<template>
  <div class="container">
    <PageTitle title="Statistiques Finances 2020" />
    <div class="card mt-4">
      <div class="card-body">
        <canvas ref="chart_elt"></canvas>
      </div>
    </div>
    <div class="card mt-4 mb-3">
      <div class="card-body">
        <canvas ref="chart_pie"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/global/PageTitle";
import Chart from "chart.js/auto";
import { onMounted, ref } from "vue";
import Utils from "moment";

export default {
  name: "Stats",
  components: { PageTitle },
  setup() {
    const chart_elt = ref();
    const chart_pie = ref();
    const labels = Utils.months();
    const data = {
      labels,
      datasets: [
        {
          label: "Solde 2020",
          data: [
            342.15, 32.64, 8.69, 638.49, 755.06, 1664.42, 1638.54, 1646.97,
            1353.56, 1237.87, 1166.03, 825.55,
          ],
          fill: false,
          borderColor: "rgb(0,108,171)",
          pointHitRadius: 10,
        },
      ],
    };
    const dataPie = {
      labels: [
        "Courses / Alimentation",
        "Restaurants / Bars",
        "Loisirs",
        "Voyages / Transports",
        "Achats Mobilier",
        "Électricité",
        "Eau",
        "Abonnements",
        "Loyer + Charges",
      ],
      datasets: [
        {
          label: "Proportion des dépenses (2020)",
          data: [264, 113, 77, 152, 47, 77, 31, 40, 673],
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(255,56,56)",
            "rgb(224,86,255)",
            "rgb(52,185,54)",
            "rgb(213,183,255)",
            "rgb(255,227,86)",
            "rgb(86,255,224)",
            "rgb(162,86,255)",
            "rgb(253,128,54)",
          ],
        },
      ],
    };
    onMounted(() => {
      const chart = new Chart(chart_elt.value, {
        type: "line",
        data,
      });
      const chartPie = new Chart(chart_pie.value, {
        type: "pie",
        data: dataPie,
      });
    });
    return { chart_elt, chart_pie };
  },
};
</script>

<style scoped></style>
