import { useStore } from "vuex";
import { computed } from "vue";
import { ConsumptionResponseYear, Filter } from "@/models/finance/energy";

export function useEnergy() {
  const { getters, dispatch, commit } = useStore();

  const consumptions = computed<ConsumptionResponseYear[]>(
    () => getters["fi_energy/getConsumptions"]
  );
  const filters = computed<Filter>(() => getters["fi_energy/getFilters"]);

  const searchConsumptions = () => dispatch("fi_energy/searchConsumptions");

  const updateFilters = async (newFilters: Filter) => {
    commit("fi_energy/update_filters", newFilters);
    await searchConsumptions();
  };

  const timeScale = computed({
    get: () => filters.value.scale,
    set: (scale) => updateFilters({ ...filters.value, scale }),
  });

  return { consumptions, searchConsumptions, timeScale };
}
