<template>
  <div class="page-title">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>

<style scoped>
.page-title {
  text-align: left;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0.7rem 1.25rem;
}
.page-title:after {
  content: "";
  height: 0.25rem;
  width: 3.25rem;
  background-color: var(--bs-primary);
  display: block;
  margin-top: 0.5rem;
}
</style>
